import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';
import { EOrderByDateType } from '../../../../../../domain/model/enums';
import BookingSidebarSlotActions from './slotActions';
import { getBookingSlotLabel } from 'features/offer/booking/components/modal/constants';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingSidebarSlotMultiple = _ref => {
  let {
    bookingOffer,
    item,
    price,
    ...actions
  } = _ref;
  return _jsxs(_Fragment, {
    children: [_jsxs(MPGrid, {
      container: true,
      spacing: 1,
      justifyContent: "space-between",
      children: [_jsx(BookingSlotItem, {
        title: getBookingSlotLabel(bookingOffer),
        children: item.slots?.map(_ref2 => {
          let {
            qty,
            startDate
          } = _ref2;
          return _jsx("div", {
            children: qty
          }, `${startDate}-${qty}`);
        })
      }), _jsx(BookingSlotItem, {
        title: "\u0414\u0430\u0442\u044B",
        children: item.slots?.map(_ref3 => {
          let {
            startDate
          } = _ref3;
          return _jsx("div", {
            children: moment(startDate).format(EDateFormat.Human)
          }, startDate);
        })
      })]
    }), _jsx(BookingSidebarSlotActions, {
      unitType: EOrderByDateType.Days,
      price: price,
      slots: item.slots,
      ...actions
    })]
  });
};
export default BookingSidebarSlotMultiple;