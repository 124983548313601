import { Text } from '../../components/controls';
import { List, ListItem } from 'presentation/features/about/components/controls';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const AboutCspWhatSizeCostInfo = () => {
  return _jsx(_Fragment, {
    children: _jsxs(Text, {
      children: [_jsxs(List, {
        children: [_jsx(ListItem, {
          children: "\u0415\u0441\u043B\u0438 \u0412\u0430\u0448\u0430 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \xAB\u0440\u0430\u0431\u043E\u0447\u0438\u0439\xBB \u2014 48 300 \u0440\u0443\u0431."
        }), _jsx(ListItem, {
          children: "\u0415\u0441\u043B\u0438 \u0412\u0430\u0448\u0430 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \xAB\u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0438 \u0441\u043B\u0443\u0436\u0430\u0449\u0438\u0435\xBB \u2014 45 540 \u0440\u0443\u0431"
        }), _jsx(ListItem, {
          children: "\u0415\u0441\u043B\u0438 \u0412\u0430\u0448\u0430 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \xAB\u0433\u043B\u0430\u0432\u043D\u044B\u0435, \u0432\u0435\u0434\u0443\u0449\u0438\u0435 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B\xBB \u2014 41 400 \u0440\u0443\u0431."
        }), _jsx(ListItem, {
          children: "\u0415\u0441\u043B\u0438 \u0412\u0430\u0448\u0430 \u0434\u043E\u043B\u0436\u043D\u043E\u0441\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \xAB\u0440\u0443\u043A\u043E\u0432\u043E\u0434\u0438\u0442\u0435\u043B\u0438 (\u043D\u0430\u0447\u0430\u043B\u044C\u043D\u0438\u043A, \u0437\u0430\u043C\u0435\u0441\u0442\u0438\u0442\u0435\u043B\u044C \u043D\u0430\u0447\u0430\u043B\u044C\u043D\u0438\u043A\u0430, \u0433\u043B\u0430\u0432\u043D\u044B\u0439 \u044D\u043A\u0441\u043F\u0435\u0440\u0442, \u0432\u0435\u0434\u0443\u0449\u0438\u0439 \u044D\u043A\u0441\u043F\u0435\u0440\u0442, \u044D\u043A\u0441\u043F\u0435\u0440\u0442, \u0434\u0440\u0443\u0433\u0438\u0435)\xBB \u2014 34 500 \u0440\u0443\u0431."
        }), _jsx(ListItem, {
          children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u0438 \u043D\u0430 1 \u0447\u043B\u0435\u043D\u0430 \u0441\u0435\u043C\u044C\u0438 \u0441\u043E\u0441\u0442\u0430\u0432\u043B\u044F\u0435\u0442 \u2014 28 960 \u0440\u0443\u0431."
        })]
      }), "\u0421\u0443\u043C\u043C\u044B \u043A\u043E\u043C\u043F\u0435\u043D\u0441\u0430\u0446\u0438\u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u044F\u044E\u0442\u0441\u044F \u043A\u0430\u0436\u0434\u044B\u0439 \u0433\u043E\u0434."]
    })
  });
};
export default AboutCspWhatSizeCostInfo;