import { Link, Typography } from '@mui/material';
import { EAddressOption } from 'domain/model/enums';
import { AddressFullView } from 'presentation/components/common/address';
import SectionTitle from 'presentation/components/common/sectionTitle';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { normalizedLink } from 'presentation/utils';
import { phoneFormatView } from 'presentation/utils/phone';
import useGridSpacing from '../../../../theme/hooks/useGridSpacing';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const PartnerDeskContact = _ref => {
  let {
    contact
  } = _ref;
  const {
    name,
    phone,
    email,
    address
  } = contact;
  const feedbackLink = contact.feedbackLink ?? null;
  const {
    x2
  } = useGridSpacing();
  return _jsxs(MPGrid, {
    container: true,
    spacing: x2,
    direction: "column",
    children: [_jsx(MPGrid, {
      item: true,
      zero: true,
      children: _jsx(Typography, {
        variant: "h3",
        children: name
      })
    }), address && _jsxs(MPGrid, {
      item: true,
      children: [_jsx(SectionTitle, {
        gutterBottom: false,
        children: "\u0410\u0434\u0440\u0435\u0441"
      }), _jsx(AddressFullView, {
        address: address,
        options: [EAddressOption.PostalCode]
      })]
    }), phone && _jsxs(MPGrid, {
      item: true,
      children: [_jsx(SectionTitle, {
        gutterBottom: false,
        children: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D"
      }), _jsx(Typography, {
        component: Link,
        href: `tel:${phoneFormatView(phone)}`,
        color: "textPrimary",
        underline: "hover",
        children: phoneFormatView(phone)
      })]
    }), email && _jsxs(MPGrid, {
      item: true,
      children: [_jsx(SectionTitle, {
        gutterBottom: false,
        children: "\u042D\u043B\u0435\u043A\u0442\u0440\u043E\u043D\u043D\u0430\u044F \u043F\u043E\u0447\u0442\u0430"
      }), _jsx(Typography, {
        component: Link,
        href: `mailto:${email}`,
        color: "textPrimary",
        underline: "hover",
        children: email
      })]
    }), feedbackLink && _jsxs(MPGrid, {
      item: true,
      children: [_jsx(SectionTitle, {
        gutterBottom: false,
        children: "\u0421\u0441\u044B\u043B\u043A\u0430 \u043D\u0430 \u0444\u043E\u0440\u043C\u0443 \u043E\u0431\u0440\u0430\u0442\u043D\u043E\u0439 \u0441\u0432\u044F\u0437\u0438"
      }), _jsx(Typography, {
        component: Link,
        href: normalizedLink(feedbackLink),
        target: "_blank",
        color: "textPrimary",
        underline: "hover",
        children: feedbackLink
      })]
    })]
  });
};
export default PartnerDeskContact;