import React from 'react';
import { Typography } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPFormInput } from 'presentation/theme/ui-kit/input';
import Splitter from 'presentation/components/common/splitter';
import { ESlotNameArea, SlotsGridArea } from '../claim/controls';
import BookingSidebarSlot from './slot';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BlankButton = _jsx(MPButton, {
  fullWidth: true,
  disabled: true,
  children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043C\u0438\u043D\u0438\u043C\u0443\u043C \u043E\u0434\u043D\u0443 \u0443\u0441\u043B\u0443\u0433\u0443"
}, 'disabled');
const useBookingSidebarSlots = _ref => {
  let {
    isLoading,
    cart,
    totalPrice,
    customerComment,
    onSelectItem,
    onDeleteItem,
    onOrder,
    onChangeCustomerComment
  } = _ref;
  let slots = cart?.map((item, cartIndex) => _jsx(BookingSidebarSlot, {
    divider: cartIndex !== cart.length - 1,
    slotIndex: cartIndex,
    onChange: onSelectItem,
    onDelete: onDeleteItem,
    ...item
  }, item.orderItem.priceItem.id)) ?? [];
  if (!cart || cart.length === 0) {
    slots = [BlankButton];
  }
  if (cart?.length) {
    slots = slots.concat(_jsxs(SlotsGridArea, {
      area: ESlotNameArea.bottomBar,
      children: [_jsx(Splitter, {
        size: 1
      }), _jsx(MPFormInput, {
        multiline: true,
        fullWidth: true,
        rows: 3,
        inputProps: {
          maxLength: 1000
        },
        label: "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u0432\u0430\u0448\u0438 \u043F\u043E\u0436\u0435\u043B\u0430\u043D\u0438\u044F",
        size: "small",
        value: customerComment,
        onChange: event => onChangeCustomerComment(event.target.value)
      }), _jsx(Splitter, {
        size: 3
      }), _jsxs(MPGrid, {
        container: true,
        spacing: 1,
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        children: [_jsx(MPGrid, {
          item: true,
          children: _jsx(Typography, {
            variant: "subtitle2",
            children: "\u041F\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043B\u044C\u043D\u0430\u044F \u0441\u0443\u043C\u043C\u0430:"
          })
        }), _jsx(MPGrid, {
          item: true,
          children: _jsx(Typography, {
            variant: "h2",
            noWrap: true,
            children: totalPrice
          })
        })]
      }), _jsx(Splitter, {
        size: 3
      }), _jsx(MPButton, {
        fullWidth: true,
        disabled: isLoading || !onOrder,
        color: "brand",
        size: "large",
        onClick: onOrder || undefined,
        children: "\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u044C \u0437\u0430\u043A\u0430\u0437"
      }), _jsx(Splitter, {
        size: 1
      }), _jsx(Typography, {
        variant: "body2",
        children: "\u0423\u043A\u0430\u0437\u0430\u043D\u043D\u0430\u044F \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0437\u0430\u043A\u0430\u0437\u0430 \u044F\u0432\u043B\u044F\u0435\u0442\u0441\u044F \u043F\u0440\u0435\u0434\u0432\u0430\u0440\u0438\u0442\u0435\u043B\u044C\u043D\u043E\u0439. \u041F\u043E\u0441\u043B\u0435 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u0438\u044F \u0437\u0430\u043A\u0430\u0437\u0430 \u0432\u0430\u043C\xA0\u043F\u0435\u0440\u0435\u0437\u0432\u043E\u043D\u0438\u0442 \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440 \u0434\u043B\u044F \u0443\u0442\u043E\u0447\u043D\u0435\u043D\u0438\u044F \u0434\u0435\u0442\u0430\u043B\u0435\u0439 \u0437\u0430\u043A\u0430\u0437\u0430 \u0438\xA0\u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438"
      })]
    }, ESlotNameArea.bottomBar));
  }
  return slots;
};
export default useBookingSidebarSlots;