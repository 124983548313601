import moment from 'moment-timezone';
import { Typography } from '@mui/material';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { MPRangeCalendarPicker } from 'presentation/theme/ui-kit/picker';
import CountInput from 'presentation/components/common/countInput';
import Splitter from 'presentation/components/common/splitter';
import { countWidth, TableHeader } from './constants';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingOfferModalPeriod = _ref => {
  let {
    bookingOffer,
    slots,
    index,
    setSlots
  } = _ref;
  const {
    qty = 1,
    startDate,
    endDate
  } = slots[index] ?? {};
  const handleChangeRange = dates => {
    setSlots(state => {
      const updatedState = [...state];
      updatedState[index] = {
        ...(state[index] ?? {
          qty: 1
        }),
        startDate: dates?.startDate ?? null,
        endDate: dates?.endDate ?? null
      };
      return updatedState;
    });
  };
  const handleChangeCount = qty => {
    setSlots(state => {
      const updatedState = [...state];
      if (!qty) {
        updatedState.splice(index, 1);
      } else {
        updatedState[index] = {
          ...updatedState[index],
          qty
        };
      }
      return updatedState;
    });
  };
  const rangeValue = startDate || endDate ? [startDate, endDate].filter(Boolean) : [];
  return _jsxs(_Fragment, {
    children: [_jsx(MPRangeCalendarPicker, {
      limitPastSelection: true,
      value: rangeValue,
      onChange: handleChangeRange
    }), startDate && endDate && _jsxs(_Fragment, {
      children: [_jsx(TableHeader, {
        bookingOffer: bookingOffer
      }), _jsxs(MPGrid, {
        container: true,
        justifyContent: "space-between",
        alignItems: "center",
        children: [_jsx(MPGrid, {
          item: true,
          children: _jsxs(Typography, {
            variant: "body1",
            component: "div",
            children: ["\u0441 ", moment(startDate).format(EDateFormat.Human), _jsx(Splitter, {
              size: 0.25
            }), "\u043F\u043E ", moment(endDate).format(EDateFormat.Human)]
          })
        }), _jsx(MPGrid, {
          item: true,
          width: countWidth,
          children: _jsx(CountInput, {
            isInputDisabled: true,
            min: 0,
            size: "micro",
            value: qty,
            onIncrement: handleChangeCount,
            onDecrement: handleChangeCount
          })
        })]
      })]
    })]
  });
};
export default BookingOfferModalPeriod;