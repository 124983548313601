import { Text } from '../../components/controls';
import { LinkCSPObjects } from './link';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AboutCspWhatObjectsInfo = () => {
  return _jsxs(Text, {
    children: ["\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0432\u044B\u0431\u0440\u0430\u0442\u044C \u043E\u0434\u0438\u043D \u0438\u0437 \u043E\u0431\u044A\u0435\u043A\u0442\u043E\u0432 \u043F\u0435\u0440\u0435\u0439\u0434\u044F ", _jsx(LinkCSPObjects, {
      children: "\u043F\u043E \u0441\u0441\u044B\u043B\u043A\u0435"
    }), "."]
  });
};
export default AboutCspWhatObjectsInfo;