import { Divider, Typography } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const getBookingPeriodHelperText = offer => `
Выберите периоды, в которые планируете свой отдых. Для начала выбора периода нажмите на дату, в которую планируете въезд. 
Для завершения выбора периода выберите дату выезда. При однодневном заселении необходимо выбрать даты последовательно. 
Отметьте количество ${getBookingSlotLabel(offer).toLowerCase()} для заселения. 
`;
export const getBookingDaysHelperText = offer => `
Выберите даты, в которые планируете посетить услуги. Даты можно выбирать не последовательно. 
Для получения услуги необходимо выбрать как минимум одну дату. 
Отметьте количество ${getBookingSlotLabel(offer).toLowerCase()} для получения услуги в выбранную дату.
`;
export const countWidth = '104px';
export const getBookingSlotLabel = offer => {
  return offer.rzdSocialPackage === true ? 'Номеров' : 'Гостей';
};
export const TableHeader = _ref => {
  let {
    bookingOffer
  } = _ref;
  return _jsxs(MPGrid, {
    container: true,
    justifyContent: "space-between",
    children: [_jsx(Splitter, {
      size: 2
    }), _jsx(MPGrid, {
      item: true,
      children: _jsx(Typography, {
        variant: "body2",
        color: "textSecondary",
        children: "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u0434\u0430\u0442\u044B"
      })
    }), _jsx(MPGrid, {
      item: true,
      width: countWidth,
      children: _jsx(Typography, {
        variant: "body2",
        color: "textSecondary",
        children: getBookingSlotLabel(bookingOffer)
      })
    }), _jsx(Splitter, {
      size: 1
    }), _jsx(MPGrid, {
      item: true,
      xs: 12,
      children: _jsx(Divider, {})
    }), _jsx(Splitter, {
      size: 1.5
    })]
  });
};