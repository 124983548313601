import { getCspOffersListRoute } from '../../../offer/csp/routes';
import { Link } from './controls';
import { Link as RouterLink } from 'react-router-dom';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LinkCSPObjects = _ref => {
  let {
    children
  } = _ref;
  return _jsx(RouterLink, {
    target: "_blank",
    to: getCspOffersListRoute(),
    children: _jsx(Link, {
      underline: 'none',
      color: "primary",
      children: children
    })
  });
};