import { Divider, Typography } from '@mui/material';
import { EOrderByDateType } from 'domain/model/enums';
import { toRubCurrency } from 'presentation/utils/currency';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import { ESlotNameArea, SlotsGridArea } from '../claim/controls';
import { BookingSlotItem } from './slotItem';
import BookingSidebarSlotRange from './slotRange';
import BookingSidebarSlotMultiple from './slotMultiple';
import BookingSidebarSlotActions from './slotActions';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const BookingSidebarSlot = _ref => {
  let {
    bookingOffer,
    priceUnit,
    orderItem,
    divider,
    slotIndex,
    ...actions
  } = _ref;
  const item = priceUnit.priceItems?.find(_ref2 => {
    let {
      id
    } = _ref2;
    return id === orderItem.priceItem.id;
  });
  if (!item) {
    return null;
  }
  const isNoDate = priceUnit.orderByDateType === EOrderByDateType.None;
  return _jsxs(SlotsGridArea, {
    area: ESlotNameArea.title,
    children: [_jsx(Typography, {
      variant: "subtitle1",
      children: priceUnit.name
    }), _jsxs(Typography, {
      variant: "body1",
      mb: 1.5,
      children: [item.name, " \u2014 ", toRubCurrency(item.price), " ", item.unit?.name?.toLocaleLowerCase() ?? '']
    }), priceUnit.orderByDateType === EOrderByDateType.Period && _jsx(BookingSidebarSlotRange, {
      bookingOffer: bookingOffer,
      ...actions,
      price: item.price ?? 0,
      item: orderItem,
      index: slotIndex
    }), priceUnit.orderByDateType === EOrderByDateType.Days && _jsx(BookingSidebarSlotMultiple, {
      bookingOffer: bookingOffer,
      ...actions,
      price: item.price ?? 0,
      item: orderItem,
      index: slotIndex
    }), isNoDate && _jsxs(_Fragment, {
      children: [_jsx(MPGrid, {
        container: true,
        spacing: 1,
        children: _jsx(BookingSlotItem, {
          title: "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E",
          children: orderItem.qty
        })
      }), _jsx(BookingSidebarSlotActions, {
        ...actions,
        unitType: EOrderByDateType.None,
        price: item.price ?? 0,
        slots: [{
          qty: orderItem.qty
        }],
        index: slotIndex
      })]
    }), divider && _jsxs(MPGrid, {
      item: true,
      xs: 12,
      children: [_jsx(Splitter, {
        size: 2
      }), _jsx(Divider, {
        color: "secondaryLight"
      })]
    })]
  }, ESlotNameArea.title);
};
export default BookingSidebarSlot;