import { Fragment } from 'react';
import moment from 'moment-timezone';
import { EDateFormat } from 'domain/model/formats';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { BookingSlotItem } from './slotItem';
import { getBookingSlotLabel } from 'features/offer/booking/components/modal/constants';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BookingOrderItemSlotRange = _ref => {
  let {
    bookingOffer,
    slots
  } = _ref;
  return slots.map(slot => _jsxs(Fragment, {
    children: [_jsxs(MPGrid, {
      container: true,
      spacing: 1,
      children: [_jsx(BookingSlotItem, {
        title: "\u041F\u0440\u0438\u0431\u044B\u0442\u0438\u0435",
        children: moment(slot.startDate).format(EDateFormat.Human)
      }), _jsx(BookingSlotItem, {
        title: "\u0412\u044B\u0435\u0437\u0434",
        children: moment(slot.endDate).format(EDateFormat.Human)
      })]
    }), _jsx(MPGrid, {
      container: true,
      spacing: 1,
      children: _jsx(BookingSlotItem, {
        title: getBookingSlotLabel(bookingOffer),
        children: slot.qty
      })
    })]
  }, `${slot.startDate}-${slot.endDate}`));
};
export default BookingOrderItemSlotRange;